/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { colors, rgba } from '../../../styles/base';
import TwoColumnSection from '../TwoColumnSection/TwoColumnSection';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';

const TwoColumnTextSection = ({ ...props }) => {
  const twoColumnTextSectionCss = css`
    ${DeprecatedGrid} {
      > * {
        margin: 0 auto;
      }

      > div:first-of-type {
        border-right: 2px solid rgba(${rgba(colors.lightGray.two, 0.6)});
      }
    }
  `;

  return <TwoColumnSection css={twoColumnTextSectionCss} {...props} />;
};

export default TwoColumnTextSection;
